import { admin2 } from '@/api/instance'

// => 建立抽獎遊戲
export const CreateMemberGame = async ({
  shopId,
  image,
  memberGameModeId,
  name,
  startAt,
  endAt,
  description,
  notice,
  requirements,
  againDelay,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/memberGame`,
    data: {
      memberGameModeId,
      image,
      name,
      startAt,
      endAt,
      description,
      notice,
      requirements,
      againDelay,
    },
  })
  return res
}

// => 取得獎項
export const GetMemberGame = async ({ shopId, start, limit }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGame`,
    params: { start, limit },
  })
  return res
}

// => 取得獎項總數
export const GetMemberGameCount = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGame/count`,
  })
  return res
}

// => 查找獎項總數
export const FindMemberGame = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGame/${id}`,
  })
  return res
}

// => 查找獎項總數
export const UpdateMemberGame = async ({
  shopId,
  id,
  MemberGameModeId,
  name,
  startAt,
  endAt,
  description,
  notice,
  requirements,
  ImageId,
  againDelay,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/memberGame/${id}`,
    data: {
      MemberGameModeId,
      name,
      startAt,
      endAt,
      description,
      notice,
      requirements,
      ImageId,
      againDelay,
    },
  })
  return res
}

// => 查找獎項總數
export const DeleteMemberGame = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/memberGame/${id}`,
  })
  return res
}
